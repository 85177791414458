import React from 'react';
import styled from 'styled-components';
import FixedRatioImage from './FixedRatioImage';
import {format} from '../utils/date';

function NewsItem(props) {
  const {item} = props;

  return (
    <NewsItemWrapper>
      <div className="banner">
        <FixedRatioImage image={item.image} ratio={250 / 300} mode={'cover'} />
      </div>

      <div className="info">
        <span>{format(item.updated, 'YYYY/MM/DD')}</span>
        <p>{item.title}</p>
        <span>{item.outline}</span>
      </div>
    </NewsItemWrapper>
  );
}
const NewsItemWrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 50px;
  width: 100%;
  cursor: pointer;
  & > .banner {
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
  }

  & > .info {
    & > span {
      display: block;
      font-size: 13px;
      color: #595757;
      letter-spacing: 1.86px;
      line-height: 22px;
      margin-top: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & > p {
      font-size: 18px;
      color: #595757;
      letter-spacing: 2.57px;
      line-height: 22px;
    }
  }

  /* style for product item */
  & .fixed-ratio-image {
    transition: transform 0.2s ease-in, opacity 0.2s ease-in;
  }

  transition: color 0.2s ease-in;

  &:hover {
    & > .info {
      & > span {
        color: #797979;
      }
      & > p {
        color: #797979;
      }
    }
    & .fixed-ratio-image {
      transform: scale(1.05);
      opacity: 0.8;
    }
  }
`;

export default NewsItem;

import React from 'react';
import {navigate} from 'gatsby-link';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import URL_PATH from '../../UrlPath';
import FixedRatioImage from '../../components/FixedRatioImage';
import {BREAK_POINTS} from '../../domain/Constants';
import NewsItem from '../../components/NewsItem';

function NewsListPage(props) {
  const {
    pageContext: {homeData},
  } = props;
  const app = React.useContext(AppContext.Context);
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    const _fetchData = async () => {
      try {
        AppActions.setLoading(true);
        let resp = await app.actions.getNewsList({
          paging: {
            offset: 0,
            limit: 9,
          },
        });

        setList(resp.results);
      } catch (err) {
        //
      } finally {
        AppActions.setLoading(false);
      }
    };
    _fetchData();
  }, [app.actions]);

  return (
    <Wrapper>
      <div className="banner">
        <FixedRatioImage
          image={homeData.news_banner || ''}
          ratio={72 / 180}
          mode={'cover'}
        />
      </div>
      <div className="header">
        <h2>最新消息</h2>
      </div>
      <div className="container">
        {list.map((news, idx) => (
          <div
            className="news"
            key={idx}
            onClick={() => navigate(`${URL_PATH.news}?id=${news.id}`)}>
            <NewsItem item={news} />
          </div>
        ))}

        <div className="news placeholder"></div>
        <div className="news placeholder"></div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
    width: 100%;
  }

  & > .header {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    h2 {
      font-size: 30px;
      color: #595757;
      letter-spacing: 1.76px;
      text-align: center;
      padding: 0px 36px 15px;
      border-bottom: 1px solid #cccccc;
    }
  }

  & > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--content-padding);
    max-width: var(--content-max-width);
    margin: 0 auto;

    & > .news {
      width: calc((100% - 30px) / 4);
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & > .container {
      & > .news {
        width: calc((100% - 10px) / 2);
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    & > .container {
      & > .news {
        width: 100%;
      }
    }
  }
`;

export default NewsListPage;
